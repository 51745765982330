/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { navigate } from 'gatsby';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

// Ui lib components
import { Container, Col } from 'react-grid-system';

// local images
import PLAY_ICON from '../../../images/icons/play-button.svg';

// Helpers
import { productQualifierByCategory } from '../../Helpers/entities';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                           Single Film Card Component                       */
/* -------------------------------------------------------------------------- */

function SingleFilmCard({
  id,
  image,
  title,
  category,
  trailer,
  types,
  actors,
  directors,
  duration,
  releaseDate,
  countries,
  showSessions,
  showTrailer,
  showLanguage,
  noRedirection,
  language,
}) {
  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation('Common');

  /* ----------------------------- Local Varaibles ---------------------------- */

  /* eslint-disable indent */
  const durationString = duration
    ? ` (${Math.floor(duration / 60)}h ${duration % 60}
              min)`
    : ` (${t('notSpecified')})`;

  const date = releaseDate
    ? format(new Date(releaseDate), 'd MMMM Y', {
        locale: fr,
      })
    : ` ${t('notSpecified')}`;

  const time = releaseDate
    ? format(new Date(releaseDate), 'HH:mm', {
        locale: fr,
      })
    : ` ${t('notSpecified')}`;
  /* eslint-enable indent */

  /* ******************************** RENDERING ******************************* */
  return (
    <Col
      className="single-film-card flex items-center fluid"
      xxl={5}
      xl={5}
      lg={5}
      md={12}
      sm={12}
      xs={12}
    >
      {noRedirection ? (
        <img className="film-img" src={image} alt="film" />
      ) : (
        <a href={`/product/${productQualifierByCategory(category).key}/${id}`}>
          <img className="film-img" src={image} alt="film" />
        </a>
      )}
      <div className="details">
        <p className="title">{title}</p>
        <p>
          <b>{t('movieMetadata.releaseDate')}</b>
          <span>
            {date}
            {durationString}
          </span>
        </p>
        <p>
          <b>{t('movieMetadata.by')}</b>
          <span>{directors || t('notSpecified')}</span>
        </p>
        <p>
          <b>{t('movieMetadata.with')}</b>
          <span>{actors || t('notSpecified')}</span>
        </p>
        <p>
          <b>{t('movieMetadata.genres')}</b>
          <span>{types || t('notSpecified')}</span>
        </p>
        <p>
          <b>{t('movieMetadata.countries')}</b>
          <span>{countries || t('notSpecified')}</span>
        </p>
        <Container className="actions flex items-center fluid">
          {showSessions && (
            <button
              className="sessions"
              type="button"
              onClick={() => navigate(`/movie/${id}#movie-sessions`)}
            >
              {t('cards.film.sessions')}
            </button>
          )}
          {showTrailer && trailer && (
            <button
              className="trailers"
              type="button"
              onClick={() => window.open(trailer, '_blank')}
            >
              <img src={PLAY_ICON} alt="play trailer" />
              {t('cards.film.trailers')}
            </button>
          )}
          {showLanguage && language && (
            <Container className="language fluid">
              <p>
                <b>{time}</b>
                {language || t('notSpecified')}
              </p>
            </Container>
          )}
        </Container>
      </div>
    </Col>
  );
}

SingleFilmCard.propTypes = {
  id: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  trailer: PropTypes.string,
  title: PropTypes.string.isRequired,
  actors: PropTypes.string.isRequired,
  directors: PropTypes.string.isRequired,
  countries: PropTypes.string.isRequired,
  releaseDate: PropTypes.string,
  duration: PropTypes.number.isRequired,
  types: PropTypes.string.isRequired,
  language: PropTypes.string,
  showSessions: PropTypes.bool,
  showTrailer: PropTypes.bool,
  showLanguage: PropTypes.bool,
  noRedirection: PropTypes.bool,
};

SingleFilmCard.defaultProps = {
  showSessions: false,
  showTrailer: false,
  showLanguage: false,
  noRedirection: false,
  trailer: undefined,
  releaseDate: undefined,
  language: undefined,
};

export default SingleFilmCard;
