/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import VenueLayout from '../page-components/Venue/VenueLayout';

/* -------------------------------------------------------------------------- */
/*                                Venue Page                               */
/* -------------------------------------------------------------------------- */

function VenuePage({ params }) {
  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation();

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={t('title')} />
      <VenueLayout id={params.id} />
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["VenuePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

VenuePage.propTypes = {
  params: PropTypes.oneOfType([PropTypes.object]),
};
VenuePage.defaultProps = {
  params: undefined,
};

export default VenuePage;
