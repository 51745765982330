/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, {
  useState,
  useCallback,
  useContext,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

// UI lib components
import { Col, Container } from 'react-grid-system';

// Context
import { DateContext } from '../../../context/DateContext';

// UI local components
import SingleFilmCard from '../../../shared/UIKit/SingleFilmCard';
import Spinner from '../../../shared/UIKit/Spinner';
import MAP_ICON from '../../../images/icons/black-map.svg';

// Constant
import { SUCCESSFUL_RESPONSE_CODE } from '../../../shared/constants';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                              VenueLayout Component                            */
/* -------------------------------------------------------------------------- */

function VenueLayout({ id }) {
  /* ********************************** HOOKS ********************************* */

  //   Localization
  const { t } = useTranslation('Common');

  // Context
  const context = useContext(DateContext);

  // State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [venue, setVenue] = useState({});
  /* ******************************** CONSTANTS ******************************* */
  const { GATSBY_GOOGLE_MAPS_URL } = process.env;
  /* ***************************** LOCAL VARIABLES **************************** */

  const selectedDate = context?.selectedDate;

  const fetchVenue = useCallback(async () => {
    if (selectedDate) {
      try {
        setLoading(true);
        const result = await axios.get(
          `${process.env.GATSBY_API_URL}/venue/${id}?eventsStartingOn=${selectedDate}`,
        );
        if (result.status === SUCCESSFUL_RESPONSE_CODE) {
          setVenue(result.data);
        } else {
          setError(
            "Une erreur s'est produite. Merci de réessayer ultérieurement.",
          );
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(
          "Une erreur s'est produite. Merci de réessayer ultérieurement.",
        );
      }
    }
  }, [selectedDate, id]);

  /* ********************************** HOOKS ********************************* */

  // Side-effets
  useLayoutEffect(() => {
    fetchVenue();
  }, [selectedDate, fetchVenue]);

  /* ***************************** RENDER HELPERS ***************************** */

  /**
   * Renders events for a given venue within a given day
   * @returns
   */
  function getVenueProductEvents(products) {
    return (
      <Container className="all-movies fluid">
        {products.map((p) => (
          <>
            <SingleFilmCard
              id={p.uniqueId}
              category={p.category.key}
              title={p.title}
              types={p.types?.map((type) => type.name)?.join(', ')}
              actors={p.productDetails?.actors}
              directors={p?.directors}
              image={
                process.env.GATSBY_STORAGE_URL +
                (p.cover?.original ?? p.cover?.thumbnail)
              }
              releaseDate={p.releaseDate}
              trailer={p.previewExternalVideo}
              language={undefined}
              showTrailer
              showLanguage
              noRedirection
            />
            <hr />
          </>
        ))}
      </Container>
    );
  }

  function getVenueProductsByDay() {
    return (
      <>
        <div className="map-location">
          <object data={MAP_ICON} type="image/svg+xml">
            map icon
          </object>

          <a
            target="_blank"
            href={`${GATSBY_GOOGLE_MAPS_URL}/search/?api=1&query=${venue.coordinates}`}
            rel="noreferrer"
          >
            {t('exhibitionMetadata.map')}
          </a>
        </div>

        <p className="date">
          {Object.entries(venue.products ?? {}).map(([day, products]) => {
            return (
              <>
                <p className="date">
                  {format(new Date(day), 'EEEE d MMMM Y', {
                    locale: fr,
                  })}
                  {getVenueProductEvents(products)}
                </p>
              </>
            );
          })}
        </p>
      </>
    );
  }

  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="venue-layout flex fluid">
      <Col className=" fluid" xxl={9} xl={9} lg={9} md={9} sm={12} xs={12}>
        {!loading ? (
          <>
            {!error ? (
              <Container className="venue-header fluid">
                {getVenueProductsByDay()}
              </Container>
            ) : (
              <h2 className="error">{error}</h2>
            )}
          </>
        ) : (
          <div className="spinner-container">
            <Spinner />
          </div>
        )}
      </Col>
    </Container>
  );
}

VenueLayout.propTypes = {
  id: PropTypes.string.isRequired,
};

export default VenueLayout;
